import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
    name: "Home",
    component: () => import("@/components/home"),
  },
  {
    path: "/info",
    name: "Informazioni",
    component: () => import("@/components/informazioni.vue"),
  },
  {
    path: "/catalogo",
    name: "Catalogo",
    component: () => import("@/components/catalogo"),
  },
  {
    path: "/ricerche",
    name: "Ricerche",
    component: () => import("@/components/ricerche"),
  },
  {
    path: "/pubblicazioni",
    name: "Pubblicazioni",
    component: () => import("@/components/pubblicazioni"),
  },
  {
    path: "/m",
    name: "Manganelli",
    component: () => import("@/components/manganelli.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
