import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dataReady: {
      periodi: false,
      autori: false,
      opere: false,
      fonti: false,
      pubbl: false,
      omografi: false,
    },
    periodi: [],
    autori: [],
    opere: [],
    fonti: [],
    pubbl: [],
    omografi: [],
  },
  getters: {
    isPeriodiLoaded(state) {
      return state.dataReady.periodi;
    },
    isAutoriLoaded(state) {
      return state.dataReady.autori;
    },
    isOpereLoaded(state) {
      return state.dataReady.opere;
    },
    isFontiLoaded(state) {
      return state.dataReady.fonti;
    },
    isPubblLoaded(state) {
      return state.dataReady.pubbl;
    },
    isOmografiLoaded(state) {
      return state.dataReady.omografi;
    },
    getPeriodi(state) {
      return state.periodi;
    },
    getAutori(state) {
      return state.autori;
    },
    getOpere(state) {
      return state.opere;
    },
    getFonti(state) {
      return state.fonti;
    },
    getPubbl(state) {
      return state.pubbl;
    },
    getOmografi(state) {
      return state.omografi;
    },
  },
  mutations: {
    setPeriodi(state, payload) {
      console.log("STORE| mutations | setPeriodi");
      state.dataReady.periodi = true;
      state.periodi = payload;
    },
    setAutori(state, payload) {
      console.log("STORE | mutations | setAutori");
      state.dataReady.autori = true;
      state.autori = payload;
    },
    setOpere(state, payload) {
      console.log("STORE | mutations | setOpere");
      state.dataReady.opere = true;
      state.opere = payload;
    },
    setFonti(state, payload) {
      console.log("STORE | mutations | setFonti");
      state.dataReady.fonti = true;
      state.fonti = payload;
    },
    setPubbl(state, payload) {
      console.log("STORE | mutations | setPubbl");
      state.dataReady.pubbl = true;
      state.pubbl = payload;
    },
    setOmografi(state, payload) {
      console.log("STORE | mutations | setOmografi");
      state.dataReady.omografi = true;
      state.omografi = payload;
    },
  },
  actions: {
    loadPeriodi({ commit }) {
      console.log("STORE | actions | loadPeriodi()");
      axios
        .post("https://poesialirica.it/ws/qry-periodi-01.php")
        .then((res) => commit("setPeriodi", res.data))
        .catch((err) => console.log(err));
    },
    loadAutori({ commit }) {
      console.log("STORE | actions | loadAutori()");
      axios
        .post("https://poesialirica.it/ws/qry-autori-01.php")
        .then((res) => commit("setAutori", res.data))
        .catch((err) => console.log(err));
    },
    loadOpere({ commit }) {
      console.log("STORE | actions | loadOpere()");
      axios
        .post("https://poesialirica.it/ws/qry-opere-01.php")
        .then((res) => commit("setOpere", res.data))
        .catch((err) => console.log(err));
    },
    loadFonti({ commit }) {
      console.log("STORE | actions | loadFonti()");
      axios
        .post("https://poesialirica.it/ws/qry-fonti-01.php")
        .then((res) => commit("setFonti", res.data))
        .catch((err) => console.log(err));
    },
    loadPubbl({ commit }) {
      console.log("STORE | actions | loadPubbl()");
      axios
        .post("https://poesialirica.it/ws/qry-pubbl-01.php")
        .then((res) => commit("setPubbl", res.data))
        .catch((err) => console.log(err));
    },
    loadOmografi({ commit }) {
      console.log("STORE | actions | loadOmografi()");
      axios
        .post("https://poesialirica.it/ws/qry-omografi.php")
        .then((res) => commit("setOmografi", res.data))
        .catch((err) => console.log(err));
    },
  },
});
